<template>
    <div class="settings">
        <div class="inner">
            <h1>Settings</h1>

            <section>
                <h2>Websites</h2>

                <ul>
                    <li v-for="website in websites" :key="website.id">
                        <div class="wrapper">
                            <span>{{ website.name }}</span>
                            <router-link
                                :to="`/website/${website.id}`"
                                tag="button"
                                >Edit</router-link
                            >
                        </div>
                    </li>
                    <li>
                        <form>
                            <input
                                placeholder="my-website.com"
                                v-model="newWebsiteName"
                            />
                            <button
                                v-on:click.prevent="submitForm"
                                class="positive"
                            >
                                Create
                            </button>
                        </form>
                    </li>
                </ul>
            </section>

            <section>
                <h2>Users</h2>

                <p>You can add other users to your account.</p>

                <ul>
                    <li>
                        <router-link :to="{ name: 'new-user' }"
                            >Add new user</router-link
                        >
                    </li>
                </ul>
            </section>

            <section>
                <h2>Tracking code</h2>

                <p>Insert this before the closing body tag.</p>
            </section>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            websites: [],
            newWebsiteName: "",
        };
    },
    methods: {
        async fetchWebsites() {
            let response = await axios.get("/api/ui/website/?__order=name");
            this.websites = response.data.rows;
        },
        async submitForm() {
            // TODO - validate the name ... make sure it's valid URL.
            await axios.post("/api/ui/website/", {
                name: this.newWebsiteName,
            });
            this.newWebsiteName = "";
            await this.fetchWebsites();
        },
    },
    async mounted() {
        await this.fetchWebsites();
    },
};
</script>
