<template>
    <svg
        v-if="series.length > 0"
        v-bind:height="yPixels + 100"
        v-bind:viewBox="`0 0 ${xPixels + 100} ${yPixels + 100}`"
        v-bind:width="xPixels + 100"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g transform="translate(40 40)">
            <!-- X Markers -->
            <g
                v-bind:key="'xMarker' + xMarker.index"
                v-for="xMarker in xMarkers"
            >
                <line
                    stroke="darkGrey"
                    v-bind:x1="xMarker.position + 40"
                    v-bind:x2="xMarker.position + 40"
                    v-bind:y1="yPixels"
                    v-bind:y2="yPixels + 20"
                />
                <text
                    class="small"
                    text-anchor="end"
                    v-bind:x="xMarker.position + 30"
                    v-bind:y="yPixels + 30"
                >
                    {{ xMarker.value }}
                </text>
            </g>

            <!-- Y Markers -->
            <g
                v-bind:key="'yMarker' + yMarker.index"
                v-for="yMarker in yMarkers"
            >
                <line
                    stroke="darkGrey"
                    :y1="yPixels - yMarker.position"
                    :y2="yPixels - yMarker.position"
                    x1="0"
                    x2="40"
                />
                <text class="small" :y="yPixels - yMarker.position - 5" x="0">
                    {{ yMarker.value }}
                </text>
            </g>
        </g>

        <g transform="translate(80 40)">
            <!-- X-axis -->
            <line
                stroke="darkGrey"
                v-bind:x2="xPixels"
                v-bind:y1="yPixels"
                v-bind:y2="yPixels"
                x1="0"
            />

            <!-- Y-axis -->
            <line stroke="darkGrey" :y2="yPixels" x1="0" x2="0" y1="0" />

            <!-- Points -->
            <circle
                r="4"
                :class="{
                    point: !point.highlight,
                    highlight: point.highlight,
                }"
                :cx="point.x - 2"
                :cy="point.y"
                :key="point.index"
                v-for="point in points"
            />

            <!-- Connecting Lines -->
            <line
                stroke="darkGrey"
                v-for="(connectingLine, index) in connectingLines"
                :key="`${index}-connectingLine`"
                :x1="connectingLine.start.x"
                :y1="connectingLine.start.y"
                :x2="connectingLine.end.x"
                :y2="connectingLine.end.y"
            />
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        xMax: {
            type: Number,
            default: 100,
        },
        yMax: {
            type: Number,
            default: 100,
        },
        xPixels: {
            type: Number,
            default: 840,
        },
        yPixels: {
            type: Number,
            default: 540,
        },
        series: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {
        yConversionFactor() {
            // Multiply by this number to get values to use on the SVG.
            return this.yPixels / this.yMax;
        },
        xMarkers() {
            let xInterval = this.xPixels / this.series.length;

            let markers = [];

            let range = this.series.length;

            for (let i = 0; i <= range; i++) {
                markers.push({
                    index: i,
                    value: "",
                    position: xInterval * i,
                });
            }

            return markers;
        },
        yMarkers() {
            // Always in intervals of some power of 10.
            let digits = Math.floor(Math.log10(this.yMax));
            let maxInt = Math.ceil(this.yMax / 10 ** digits);

            if (maxInt == 1) {
                digits += -1;
                maxInt = 10;
            }

            let markers = [];

            let thousand = 10 ** 3;
            let million = 10 ** 6;
            let billion = 10 ** 9;
            let trillion = 10 ** 12;

            for (let i = 0; i <= maxInt; i++) {
                let value = i * Math.pow(10, digits);

                if (value < 1) {
                    value = value.toFixed(1);
                }

                let valueString = "";

                if (value == 0) {
                    valueString = String(value);
                } else if (12 <= digits) {
                    valueString = `${value / trillion}T`;
                } else if (9 <= digits && digits <= 12) {
                    valueString = `${value / billion}B`;
                } else if (6 <= digits && digits <= 9) {
                    valueString = `${value / million}M`;
                } else if (3 <= digits && digits <= 6) {
                    valueString = `${value / thousand}k`;
                } else {
                    valueString = String(value);
                }

                let position = value * this.yConversionFactor;
                if (position <= this.yPixels) {
                    markers.push({
                        index: i,
                        value: valueString,
                        position: position,
                    });
                }
            }

            return markers;
        },
        points() {
            let xInterval = this.xPixels / this.series.length;

            return this.series.map((value, index) => {
                return {
                    y: this.yPixels - value * this.yConversionFactor,
                    x: index * xInterval,
                    index: index,
                    highlight: index == 0 || index + 1 == this.series.length,
                };
            });
        },
        // Lines between the points.
        connectingLines() {
            let output = [];

            let seriesCount = this.points.length;

            for (let index = 0; index < seriesCount; index++) {
                if (index + 1 == seriesCount) {
                    continue;
                }

                const firstPoint = this.points[index];
                const secondPoint = this.points[index + 1];

                output.push({ start: firstPoint, end: secondPoint });
            }

            return output;
        },
    },
};
</script>

<style lang="less">
@darkGrey: grey;
@lightGreen: green;
@orange: orange;

svg {
    text.small {
        font: bold 15px sans-serif;
        fill: @darkGrey;
    }
    circle.point {
        fill: @lightGreen;
    }
    circle.highlight {
        fill: @orange;
    }
}
</style>