<template>
    <div class="home">
        <div class="filters">
            <p><font-awesome-icon icon="filter" /> Filters</p>
            <form>
                <select v-model="selectedWebsite">
                    <option
                        v-for="website in websites"
                        :key="website.id"
                        :value="website"
                    >
                        {{ website.name }}
                    </option>
                </select>

                <select v-model="selectedInterval">
                    <option v-for="interval in intervals" :key="interval">
                        {{ interval }}
                    </option>
                </select>

                <button v-on:click.prevent="refreshData">Update</button>
            </form>
        </div>

        <section>
            <h2><font-awesome-icon icon="chart-bar" /> Views per day</h2>
            <Graph
                :series="pageViewsPerDayValues"
                :yMax="Math.max(...pageViewsPerDayValues)"
            />
        </section>

        <section>
            <h2><font-awesome-icon icon="fire" /> Most popular pages</h2>
            <table v-if="pageViews.length > 0">
                <tr>
                    <th>Path</th>
                    <th>Total page views</th>
                </tr>
                <tr v-for="pageView in pageViews" :key="pageView.path">
                    <td>
                        <a
                            :href="`https://${selectedWebsite.name}/${pageView.path}`"
                            >{{ pageView.path }}</a
                        >
                    </td>
                    <td>{{ pageView.count.toLocaleString() }}</td>
                </tr>
            </table>
            <p v-else>No data</p>
        </section>

        <section>
            <h2><font-awesome-icon icon="desktop" /> Devices</h2>
            <table v-if="deviceList.length > 0">
                <tr>
                    <th>Name</th>
                    <th>Is Mobile</th>
                    <th>Total page views</th>
                </tr>
                <tr v-for="row in deviceList" :key="row.device + row.is_mobile">
                    <td>{{ row.device ? row.device : "Unknown" }}</td>
                    <td>
                        <BooleanIcon :boolean="row.is_mobile" />
                    </td>
                    <td>{{ row.count.toLocaleString() }}</td>
                </tr>
            </table>
            <p v-else>No data</p>
        </section>

        <section>
            <h2><font-awesome-icon icon="compact-disc" /> Operating Systems</h2>
            <table v-if="osList.length > 0">
                <tr>
                    <th>Name</th>
                    <th>Is Mobile</th>
                    <th>Total page views</th>
                </tr>
                <tr v-for="row in osList" :key="row.os + row.is_mobile">
                    <td>{{ row.os ? row.os : "Unknown" }}</td>
                    <td>
                        <BooleanIcon :boolean="row.is_mobile" />
                    </td>
                    <td>{{ row.count.toLocaleString() }}</td>
                </tr>
            </table>
            <p v-else>No data</p>
        </section>

        <section>
            <h2><font-awesome-icon icon="window-maximize" /> Browsers</h2>
            <table v-if="browserList.length > 0">
                <tr>
                    <th>Name</th>
                    <th>Is Mobile</th>
                    <th>Total page views</th>
                </tr>
                <tr
                    v-for="row in browserList"
                    :key="row.browser + row.is_mobile"
                >
                    <td>{{ row.browser ? row.browser : "Unknown" }}</td>
                    <td>
                        <BooleanIcon :boolean="row.is_mobile" />
                    </td>
                    <td>{{ row.count.toLocaleString() }}</td>
                </tr>
            </table>
            <p v-else>No data</p>
        </section>

        <section>
            <h2><font-awesome-icon icon="flag" /> Countries</h2>

            <WorldMap :countryList="countryList" />

            <table v-if="countryList.length > 0">
                <tr>
                    <th>Name</th>
                    <th>Total page views</th>
                </tr>
                <tr v-for="row in countryList" :key="row.country">
                    <td>{{ row.country ? row.country : "Unknown" }}</td>
                    <td>{{ row.count.toLocaleString() }}</td>
                </tr>
            </table>
            <p v-else>No data</p>
        </section>

        <section>
            <h2><font-awesome-icon icon="link" /> Referer</h2>
            <table v-if="refererList.length > 0">
                <tr>
                    <th>Name</th>
                    <th>Total page views</th>
                </tr>
                <tr v-for="row in refererList" :key="row.referer">
                    <td>{{ row.referer ? row.referer : "Unknown" }}</td>
                    <td>{{ row.count.toLocaleString() }}</td>
                </tr>
            </table>
            <p v-else>No data</p>
        </section>
    </div>
</template>

<script>
import axios from "axios";
import BooleanIcon from "../components/BooleanIcon";
import Graph from "../components/Graph";
import WorldMap from "../components/WorldMap";

export default {
    name: "App",
    data() {
        return {
            websites: [],
            selectedWebsite: undefined,
            selectedInterval: undefined,
            intervals: [],
            pageViews: [],
            pageViewsPerDay: [],
            countryList: [],
            browserList: [],
            deviceList: [],
            refererList: [],
            osList: [],
        };
    },
    components: {
        BooleanIcon,
        Graph,
        WorldMap,
    },
    watch: {
        selectedWebsite() {
            console.log("Website changed");
        },
    },
    computed: {
        selectedWebsiteID() {
            let selectedWebsite = this.selectedWebsite;
            return selectedWebsite ? selectedWebsite.id : null;
        },
        pageViewsPerDayValues() {
            return this.pageViewsPerDay.map((object) => object.count);
        },
    },
    methods: {
        // Networking
        async fetchPageViews() {
            let response = await axios.get(
                `/api/ui/page-views/?website_id=${this.selectedWebsiteID}`
            );
            this.pageViews = response.data;
        },
        async fetchPageViewsPerDay() {
            let response = await axios.get(
                `/api/ui/page-views-per-day/?website_id=${this.selectedWebsiteID}`
            );
            this.pageViewsPerDay = response.data;
        },
        async fetchCountryList() {
            let response = await axios.get(
                `/api/ui/country-list/?website_id=${this.selectedWebsiteID}`
            );
            this.countryList = response.data;
        },
        async fetchBrowserList() {
            let response = await axios.get(
                `/api/ui/browser-list/?website_id=${this.selectedWebsiteID}`
            );
            this.browserList = response.data;
        },
        async fetchDeviceList() {
            let response = await axios.get(
                `/api/ui/device-list/?website_id=${this.selectedWebsiteID}`
            );
            this.deviceList = response.data;
        },
        async fetchRefererList() {
            let response = await axios.get(
                `/api/ui/referer-list/?website_id=${this.selectedWebsiteID}`
            );
            this.refererList = response.data;
        },
        async fetchOSList() {
            let response = await axios.get(
                `/api/ui/os-list/?website_id=${this.selectedWebsiteID}`
            );
            this.osList = response.data;
        },
        async refreshData() {
            await Promise.all([
                this.fetchPageViews(),
                this.fetchPageViewsPerDay(),
                this.fetchCountryList(),
                this.fetchBrowserList(),
                this.fetchDeviceList(),
                this.fetchOSList(),
                this.fetchRefererList(),
            ]);
        },
    },
    async mounted() {
        let response = await axios.get("/api/ui/website/?__order=name");
        this.websites = response.data.rows;
        this.selectedWebsite = this.websites[0];

        response = await axios.get("/api/ui/interval-list/");
        this.intervals = response.data;
        this.selectedInterval = this.intervals[0];

        await this.refreshData();
    },
};
</script>

<style lang="less">
@import "../vars.less";
@import "../shared.less";

div.home {
    div.filters {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 50rem;
        margin: 1rem auto;

        p {
            flex-grow: 1;
            margin: 0;
        }

        form {
            flex-grow: 0;

            input,
            select {
                margin-right: 1rem;
            }
        }
    }

    section {
        background-color: white;
        box-sizing: border-box;
        padding: 2rem;
        max-width: 50rem;
        margin: 0.5rem auto 1.5rem;

        .shadow();

        h2 {
            font-size: 1rem;
            margin-top: 0;
        }

        p {
            font-size: 0.9rem;
        }

        table {
            font-size: 0.9rem;
            table-layout: fixed;
            width: 100%;

            th {
                border-bottom: 2px solid #f0f0f0;
                font-weight: bold;
            }

            td,
            th {
                padding: 0.5rem 1rem;
                text-align: center;

                &:first-child {
                    text-align: left;
                }

                a {
                    color: @brightBlue;
                    text-decoration: none;
                }
            }
        }

        svg {
            max-width: 100%;
        }
    }

    footer {
        background-color: @darkBlue;
        box-sizing: border-box;
        margin-top: 5rem;
        padding: 5rem 1rem;

        p {
            color: rgba(255, 255, 255, 0.8);
            margin: 0;
            text-align: center;
        }
    }
}
</style>
