<template>
    <div id="login">
        <div class="inner">
            <h1>Login</h1>
            <form v-on:submit.prevent="login">
                <label>Username</label>
                <input type="text" v-model="username" />

                <label>Password</label>
                <input type="password" v-model="password" />

                <button>Login</button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data: function () {
        return {
            username: "",
            password: "",
        };
    },
    methods: {
        async login() {
            console.log("Logging in");
            try {
                var response = await axios.post("/api/login/", {
                    username: this.username,
                    password: this.password,
                });
            } catch (error) {
                console.log("Request failed");
                console.log(error.response.data.detail);
                return;
            }
            console.log(response);
            this.$router.push({ name: "home" });
        },
    },
};
</script>

<style lang="less">
div#login {
    div.inner {
        h1 {
            margin-top: 0;
            padding-top: 2rem;
        }
    }
}
</style>
