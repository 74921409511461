<template>
    <div class="inner">
        <h1>Profile</h1>
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>
