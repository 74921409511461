<template>
    <nav>
        <img src="../assets/logo.png" />
        <h1><router-link to="/">Glimmer Analytics</router-link></h1>

        <p>
            <router-link :to="{ name: 'home' }">Home</router-link>
            <router-link :to="{ name: 'settings' }">Settings</router-link>
            <router-link :to="{ name: 'profile' }"
                ><font-awesome-icon icon="user" /> Profile</router-link
            >
            <a href="#" v-on:click.prevent="logout">Logout</a>
        </p>
    </nav>
</template>

<script>
import axios from "axios";

export default {
    watch: {
        $route(to, from) {
            console.log("Route changed");
        },
    },
    methods: {
        async logout() {
            await axios.post("/api/logout/");
            this.$router.push({ name: "login" });
        },
    },
};
</script>

<style lang="less">
@import "../vars.less";

nav {
    background: linear-gradient(
        to top right,
        @brightBlue,
        lighten(@brightBlue, 10%)
    );
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 1rem;
    align-items: center;
    flex-grow: 0;

    img {
        flex-grow: 0;
        height: 2rem;
        padding-right: 0.2rem;
    }

    h1 {
        flex-grow: 1;
        font-size: 1rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        font-weight: normal;
        margin: 0;
        text-align: left;

        a {
            color: white;
            text-decoration: none;
        }
    }

    p {
        flex-grow: 0;
        margin: 0;

        a {
            color: white;
            text-decoration: none;
            margin: 0 0.8rem;

            &.router-link-exact-active {
                border-bottom: 2px solid white;
            }
        }
    }
}
</style>