<template>
    <div class="settings">
        <div class="inner">
            <p><router-link to="/settings">View all</router-link></p>
            <h1>
                Website config
                <span v-if="website">- {{ website.name }}</span>
            </h1>
        </div>

        <div class="inner">
            <section>
                <h2>Data Retention</h2>

                <form>
                    <p>
                        Analytics data is automatically deleted after a given
                        period of time.
                    </p>

                    <label>Retention period (weeks):</label>
                    <select v-model="selectedDeletionInterval">
                        <option
                            v-for="deletionInterval in deletionIntervals"
                            :key="deletionInterval"
                            :value="deletionInterval"
                        >
                            {{ deletionInterval }}
                        </option>
                    </select>

                    <button
                        class="positive"
                        v-on:click.prevent="updateDataRetention"
                    >
                        Save
                    </button>
                </form>
            </section>
        </div>

        <div class="inner">
            <section>
                <h2>Delete website</h2>

                <form>
                    <button class="negative" v-on:click.prevent="deleteWebsite">
                        Delete
                    </button>
                </form>
            </section>
        </div>

        <MessagePopup :message="message" />
    </div>
</template>

<script>
import axios from "axios";
import MessagePopup from "../components/MessagePopup";

export default {
    props: ["websiteID"],
    data() {
        return {
            website: undefined,
            selectedDeletionInterval: undefined,
            maxDeletionInterval: 52,
            message: undefined,
        };
    },
    components: {
        MessagePopup,
    },
    watch: {
        async $route() {
            this.fetchWebsite();
        },
    },
    computed: {
        deletionIntervals() {
            return Array.from(
                { length: this.maxDeletionInterval },
                (v, k) => k + 1
            );
        },
    },
    methods: {
        async fetchWebsite() {
            try {
                let response = await axios.get(
                    `/api/ui/website/${this.websiteID}/`
                );
                this.website = response.data;
                this.selectedDeletionInterval =
                    response.data.data_retention_weeks;
                if (this.selectedDeletionInterval > this.maxDeletionInterval) {
                    this.maxDeletionInterval = this.selectedDeletionInterval;
                }
            } catch (error) {
                if (error.response.status == 404) {
                    this.$router.push("/settings");
                }
            }
        },
        async deleteWebsite() {
            if (confirm("Are you sure you want to delete this website?")) {
                await axios.delete(`/api/ui/website/${this.website.id}/`);
                this.$router.push("/settings");
            }
        },
        async updateDataRetention() {
            // No CSRF protection yet.
            await axios.put(`/api/ui/website/${this.website.id}/`, {
                data_retention_weeks: this.selectedDeletionInterval,
            });
            // To make it reshow:
            this.message = undefined;
            let app = this;
            setTimeout(function () {
                app.message = "Saved";
            }, 0);
        },
    },
    async mounted() {
        await this.fetchWebsite();
    },
};
</script>

<style scoped lang="less">
select {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    box-sizing: border-box;
}
</style>
