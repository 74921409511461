<template>
    <font-awesome-icon
        :class="{ red: !boolean, green: boolean }"
        :icon="boolean ? 'check' : 'times'"
    />
</template>

<script>
export default {
    props: {
        boolean: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style scoped>
.red {
    color: red;
}
.green {
    color: green;
}
</style>