<template>
    <div id="app">
        <Nav />

        <div class="content">
            <router-view></router-view>
        </div>

        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import Footer from "./components/Footer.vue";
import Nav from "./components/Nav.vue";

export default {
    name: "App",
    components: {
        Footer,
        Nav,
    },
    async beforeCreate() {
        let app = this;

        // Handle auth errors - redirect to login.
        axios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                if (error.response && error.response.status == 401) {
                    console.log("Login required");
                    let nextURL = app.$route.path;
                    if (nextURL !== "/login") {
                        setTimeout(function () {
                            app.$router.push({
                                name: "login",
                                query: {
                                    nextURL: nextURL,
                                },
                            });
                        }, 0);
                    }
                }
                return Promise.reject(error);
            }
        );
    },
};
</script>

<style lang="less">
@import "./vars.less";
@import "./shared.less";

body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
}

a {
    color: @brightBlue;
}

button {
    color: white;
    background-color: @brightBlue;
    border: none;
    padding: 0.5rem 1rem;
    transition: 0.5s background-color;
    cursor: pointer;

    &:hover {
        background-color: lighten(@brightBlue, 10%);
        transition: 0.5s background-color;
    }

    &.negative {
        background-color: red;
    }

    &.positive {
        background-color: green;
    }
}

form {
    label {
        margin-top: 1rem;
    }
    input,
    label {
        display: block;
        width: 100%;
    }
    input {
        box-sizing: border-box;
        padding: 0.5rem;
        margin-bottom: 1rem;
    }
}

#app {
    background-color: @lightGrey;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    div.content {
        flex-grow: 1;

        div.inner {
            background-color: white;
            max-width: 50rem;
            margin: 2rem auto;
            box-sizing: border-box;
            padding: 2rem;

            .shadow();

            hr {
                border: 0;
                height: 5px;
                background-color: @lightGrey;
                margin: 2rem 0;
            }

            section {
                padding: 1rem 0;

                ul {
                    padding: 0;

                    li {
                        border-bottom: 1px solid @lightGrey;
                        list-style: none;
                        padding: 1rem;
                        box-sizing: border-box;

                        div.wrapper,
                        form {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            width: 100%;
                        }

                        &:first-child {
                            border-top: 1px solid @lightGrey;
                        }

                        &:last-child {
                            border-bottom: none;
                        }

                        span {
                            display: block;
                            flex-grow: 1;
                            font-size: 1rem;
                        }

                        button.negative {
                            flex-grow: 0;
                        }

                        input {
                            margin-right: 1rem;
                        }
                    }
                }

                form {
                    label,
                    input {
                        box-sizing: border-box;
                        display: block;
                    }

                    input {
                        border: 1px solid @lightGrey;
                        font-size: 1rem;
                        padding: 0.5rem;
                        width: 100%;
                    }

                    label,
                    input,
                    button {
                        display: block;
                        margin: 0.5rem 0;
                    }
                }
            }
        }
    }
}
</style>
