import Vue from 'vue'
import App from './App.vue'

/*****************************************************************************/

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFire, faFilter, faDesktop, faCompactDisc, faWindowMaximize, faLink, faFlag, faCheck, faTimes, faChartBar, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFire)
library.add(faFilter)
library.add(faDesktop)
library.add(faCompactDisc)
library.add(faWindowMaximize)
library.add(faLink)
library.add(faFlag)
library.add(faCheck)
library.add(faTimes)
library.add(faChartBar)
library.add(faUser)
Vue.component('font-awesome-icon', FontAwesomeIcon)

/*****************************************************************************/

import VueRouter from 'vue-router'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import NewUser from './views/NewUser.vue'
import Settings from './views/Settings.vue'
import WebsiteDetail from './views/WebsiteDetail.vue'
import Profile from './views/Profile.vue'

let router = new VueRouter({
    routes: [
        {
            path: '/',
            component: Home,
            name: 'home'
        },
        {
            path: '/settings',
            component: Settings,
            name: 'settings'
        },
        {
            path: '/website/:websiteID',
            component: WebsiteDetail,
            name: 'website-detail',
            props: true
        },
        {
            path: '/user/new',
            component: NewUser,
            name: 'new-user',
        },
        {
            path: '/login',
            component: Login,
            name: 'login',
        },
        {
            path: '/profile',
            component: Profile,
            name: 'profile',
        },
    ]
})

Vue.use(VueRouter)

/*****************************************************************************/

import axios from 'axios'
import Cookies from 'js-cookie'

// Add the CSRF token
axios.interceptors.request.use(function (config) {
    if (
        ['POST', 'PUT', 'DELETE', 'PATCH'].indexOf(
            config.method.toUpperCase()
        ) != -1
    ) {
        const csrfToken = Cookies.get('csrftoken')
        config.headers['X-CSRFToken'] = csrfToken
    }
    return config
})

/*****************************************************************************/

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router
}).$mount('#app')
