<template>
    <footer>
        <p>Glimmer</p>
    </footer>
</template>

<script>
export default {};
</script>

<style lang="less">
@import "../vars.less";

footer {
    background-color: @darkBlue;
    box-sizing: border-box;
    margin-top: 5rem;
    padding: 5rem 1rem;
    flex-grow: 0;

    p {
        color: rgba(255, 255, 255, 0.8);
        margin: 0;
        text-align: center;
    }
}
</style>
